<template>
  <div id="record">
    <div class="left">
      <div class="title">
        <img
          src="../../img/板块图标 - 副本_slices/icon.png"
          style="width: 0.18rem"
          alt=""
        />
        <span>课程列表</span>
      </div>
      <ul class="info">
        <li>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :name="v + 1" v-for="(i, v) in course" :key="v">
              <template slot="title">
                <img
                  v-show="activeName == v + 1"
                  class="img-title"
                  src="../../img/上课/矢量智能对象.png"
                  alt=""
                />
                <img
                  v-show="activeName != v + 1"
                  class="img-title"
                  src="../../img/上课/矢量智能对象 拷贝 2.png"
                  alt=""
                />
                <span :class="{ spanColor: activeName == v + 1 }">{{
                  i.course_name
                }}</span>
              </template>
              <ul class="list">
                <li
                  @click="
                    listbg = index + 1;
                    getdata(item.id);
                  "
                  :class="{
                    listbg: activeName == v + 1 && listbg == index + 1,
                  }"
                  v-for="(item, index) in i.section"
                  :key="index"
                >
                  <div class="img">
                    <img
                      v-if="listbg == index + 1"
                      src="../../img/上课/三角下标（正方形） (1).png"
                      alt=""
                    />
                  </div>
                  <span>{{ item.course_section_name }}</span>
                </li>
                <!-- <li
                  @click="listbg = 2"
                  :class="{ listbg: activeName == v + 1 && listbg == v + 2 }"
                >
                  <div class="img">
                    <img
                      v-if="listbg == 2"
                      src="../../img/上课/三角下标（正方形） (1).png"
                      alt=""
                    />
                  </div>
                  <span>02 高铁电网施工流程</span>
                </li>
                <li
                  @click="listbg = 3"
                  :class="{ listbg: activeName == v + 1 && listbg == v + 3 }"
                >
                  <div class="img">
                    <img
                      v-if="listbg == 3"
                      src="../../img/上课/三角下标（正方形） (1).png"
                      alt=""
                    />
                  </div>
                  <span>03 铁路施工概论</span>
                </li> -->
              </ul>
            </el-collapse-item>
          </el-collapse>
        </li>
      </ul>
    </div>
    <router-view :classData="classData"></router-view>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      num: 0,
      course: "",
      section: "",
      info: "",
      courseName: "",
      activeName: "1",
      listbg: 1,
      option: "",
      timer: "",
      classData: "",
    };
  },
  mounted() {
    this.getinfo();
    this.getdata();
  },
  methods: {
    getinfo() {
      util.post("/course/index", {}, (res) => {
        console.log(res);
        if (res.code == 1) {
          this.course = res.data;
          this.getdata(res.data[0].section[0].id);
        }
      });
    },
    getdata(id) {
      util.post("/data_course", { csid: id }, (res) => {
        console.log(res);
        this.classData = res.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
#record {
  height: 100%;
  display: flex;

  .left {
    width: 3.28rem /* 328/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;

    .title {
      width: 3.26rem;
      height: 0.72rem /* 72/100 */;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      border-bottom: 1px solid #cdcdcd;
      span {
        font-size: 0.2rem /* 20/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .info {
      width: 326px;
      > li {
        .img-title {
          margin-left: 22px;
          margin-right: 14px;
        }
        span {
          font-size: 16px;
        }
        .spanColor {
          color: #1c5eff;
          font-weight: 500;
        }
      }
      /deep/.el-collapse {
        border-bottom: none;
        border-top: none;
      }
      /deep/.el-collapse-item__header,
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
        background-color: transparent;
        margin: 10px 0;
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      .list {
        // background-color: #cce6ff;
        background: rgba(204, 230, 255, 0.18);
        padding-bottom: 5px;
        .listbg {
          // background: url("../../img/上课/矩形 3 拷贝 2.png");
          background-image: linear-gradient(
            to right,
            #1c5eff,
            rgb(62, 173, 247)
          );
          color: #fff;
        }
        li {
          margin: 15px 0;
          display: flex;
          height: 32px;
          line-height: 32px;
          color: #000;
          font-size: 500;
          cursor: pointer;

          span {
            font-size: 14px;
          }

          .img {
            width: 8px;
            margin-left: 33px;
            margin-right: 11px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    li:hover {
      .right {
        color: skyblue;
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>